$effectiveQuadrantHeight: 528px;
$effectiveQuadrantWidth: 528px;
$quadrantHeight: 512px;
$quadrantWidth: 512px;
$quadrantsGap: 32px;
$minBlipWidth: 12px;
$blipWidth: 22px;
$groupBlipHeight: 24px;
$newGroupBlipWidth: 88px;
$existingGroupBlipWidth: 124px;
$rings: Adopt,Trial,Assess,Holdpx;
$quadrants: Techniques,Platforms,Tools,Languages & Frameworkspx;
$groupBlipAngles: 30,35,60,80px;
$maxBlipsInRings: 8,22,17,18px;
$subnavHeight: 60px;
$bannerHeight: 200px;
$tabletBannerHeight: 300px;
$headerHeight: 80px;
$legendsHeight: 42px;
$tabletViewWidth: 1280px;
$mobileViewWidth: 768px;
$UIRefresh2022: true;
@import 'colors';
@import 'fonts';
@import 'tip';
@import 'form';
@import 'error';
@import 'header';
@import 'footer';
@import 'herobanner';
@import 'quadrantsubnav';
@import 'search';
@import 'alternativeradars';
@import 'buttons';
@import 'quadrants';
@import 'quadrantTables';
@import 'mediaqueries';
@import 'layout';
@import 'landingpage';
@import 'loader';
@import 'screen';
@import 'pdfPage';

:root {
  font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
  :root {
    font-family: 'Inter var', sans-serif;
  }
}

@media screen {
  body {
    font: 18px 'Open Sans';
    opacity: 0;
    @if $UIRefresh2022 {
      font-family: $baseFontFamily;
      opacity: 1;

      h1 {
        font-size: 1.5rem;
        font-family: 'Bitter', serif;
        text-transform: none;
        letter-spacing: normal;

        @include media-query-medium {
          font-size: 2rem;
        }

        @include media-query-large {
          font-size: 2.5rem;
        }

        @include media-query-xxlarge {
          font-size: 3rem;
        }

        @include media-query-xxxlarge {
          font-size: 3.5rem;
        }
      }

      h2 {
        font-size: 1.25rem;
        font-weight: 630;
        font-family: 'Inter', serif;
        text-transform: none;
        letter-spacing: normal;

        @include media-query-medium {
          font-size: 1.25rem;
        }

        @include media-query-large {
          font-size: 1.5rem;
        }

        @include media-query-xlarge {
          font-size: 1.5rem;
        }

        @include media-query-xxlarge {
          font-size: 2rem;
        }

        @include media-query-xxxlarge {
          font-size: 2rem;
        }
      }

      p {
        font-size: 1rem;
        font-family: $baseFontFamily;

        @include media-query-xxlarge {
          font-size: 1.125rem;
        }
      }

      a {
        color: $link-normal;
        border-color: $link-normal;

        &:hover {
          color: $link-hover;
          border-color: $link-hover;
        }
      }
    }

    -webkit-font-smoothing: antialiased;
    margin: 0;
  }

  @if $UIRefresh2022 {
    .d3-tip {
      font-size: 12px;
      display: block;
      padding: 12px;
      background: $wave;
      color: $white;
      pointer-events: none;
      z-index: 20;
      max-width: 170px;
      word-wrap: break-word;
      white-space: pre-line;
      line-height: 2;
      border-radius: unset;

      @include media-query-medium {
        max-width: 250px;
      }
    }

    .d3-tip:after {
      box-sizing: border-box;
      font-size: 10px;
      width: 100%;
      color: $wave;
      position: absolute;
      pointer-events: none;
    }

    .d3-tip.n {
      margin: -10px 0 0;
    }

    .d3-tip.n:after {
      content: '\25BC';
      margin: -3px 0 0;
      top: 100%;
      left: 0;
      text-align: center;
    }
  }
}
