.input-sheet {
  margin: 60px auto;

  p {
    font-weight: 100;
    color: $black;
    font-size: 18px;
  }

  .input-sheet__logo {
    text-align: center;
    padding-bottom: 40px;
    a {
      border-bottom: none;

      img {
        width: 200px;
      }
    }
  }

  .radar-footer {
    padding-top: 200px;
  }

  .input-sheet__banner {
    background-image: url('/images/tech-radar-landing-page-wide.png');
    background-repeat: no-repeat;
    background-color: $grey-light;
    background-size: cover;
    background-position: center;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    min-height: 285px;
    display: table;

    div {
      display: table-cell;
      vertical-align: middle;
    }
    p,
    h1 {
      color: $white;
    }

    a {
      color: $white;
      border-bottom-color: $white;
    }
  }

  .input-sheet__form {
    width: 50%;
    margin: 0 auto;
    text-align: center;
    padding-top: 30px;

    button {
      font-family: inherit;
      border: none;
      background-color: transparent;
      margin: 0;
      padding: 0;
    }
  }

  input[type='text'] {
    border-bottom: 2px solid $grey-even-darker;
    display: block;
    font-size: 18px;
    margin-bottom: 30px;
    padding: 10px;
    transition:
      box-shadow 0.3s,
      border 0.3s;

    &:focus,
    &.focus {
      outline: none;
      border-bottom: 2px solid $grey-even-darker;
      box-shadow: none;
    }
  }

  form,
  input,
  a {
    font-family: inherit;
  }
}
