@import 'colors';
@import 'layout';

.error-container {
  text-align: center;
  padding-top: 48px;

  .error-container__message {
    @if not $UIRefresh2022 {
      width: 60%;
      display: inline-block;
    }
    @if $UIRefresh2022 {
      p:first-child {
        color: $error-text;
      }
      p {
        margin: 0;
      }
    }
  }

  .error-title {
    font-weight: 400;
  }

  .error-subtitle {
    margin-top: -10px;
  }

  .switch-account-button {
    margin: 0 0 35px 0;
  }

  .switch-account-button-newui {
    background-color: $button-normal;
    color: $white;
    font-size: 20px;
    font-family: $baseFontFamily;
    line-height: 24px;
    font-weight: bold;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 10px;
    margin-bottom: 18px;
    border: none;
    text-transform: none;
    cursor: pointer;
    &:focus {
      outline: auto;
    }
  }
}

.input-sheet {
  .page-not-found {
    font-size: 40px;
    font-weight: 900;
    margin-bottom: 20px;
  }

  .message p {
    font-size: 25px;
  }
}

.support {
  margin-top: 20px;

  p {
    font-weight: 500;
    font-size: 30px;
    margin-bottom: 1px;
  }
}

.support-link {
  font-size: 26px;
  padding: 20px;

  span {
    padding: 0 40px;
    display: table-cell;
  }
}
