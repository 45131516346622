@import 'colors';
@import 'layout';

@if $UIRefresh2022 {
  .quadrant-table {
    @include layout-margin(calc(12 / 12), $screen-small);
    @include layout-margin(calc(12 / 12), $screen-medium);
    @include layout-margin(calc(12 / 12), $screen-large);
    @include layout-margin(calc(4 / 12), $screen-xlarge);
    @include layout-margin(calc(5 / 12), $screen-xxlarge);
    @include layout-margin(calc(5 / 12), $screen-xxxlarge);

    &__ring-name {
      text-transform: none;
      margin: 0;
      scroll-margin-top: $subnavHeight;
    }

    @include media-query-xlarge {
      max-width: 40%;
      margin-top: 0 !important;
    }

    &__container {
      display: flex;
      justify-content: center;

      @include media-query-xlarge {
        display: block;
        justify-content: unset;
      }
    }
  }

  .blip-list {
    width: 100%;
    margin-bottom: 64px;

    &__item {
      width: 100%;

      &:hover,
      &.highlight {
        background-color: $mist;
      }

      &-container {
        &.expand {
          background-color: $mist;
        }

        &.expand &__name {
          &-arrow {
            rotate: -135deg;
            margin-top: 10px;
          }
        }

        &.expand &__description {
          display: block;
        }

        &__name {
          padding: 20px;
          width: 100%;
          border: none;
          background-color: transparent;
          border-bottom: 1px solid $mist-s20;
          text-align: unset;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          scroll-margin-top: $subnavHeight;

          & > * {
            pointer-events: none;
          }

          &-value {
            pointer-events: none;
            display: inline-block;
            width: 90%;
            font-size: 1rem;
            font-family: $baseFontFamily;

            @include media-query-large {
              font-size: 1.125rem;
            }
          }

          &-arrow {
            width: 8px;
            height: 8px;
            display: inline-flex;
            rotate: 45deg;
            border: 1px solid $flamingo;
            border-width: 0 2px 2px 0;
            -webkit-transition: all 0.2s ease;
            transition: all 0.2s ease;
          }
        }

        &__description {
          display: none;
          padding: 20px;
          font-size: 1rem;
          font-family: $baseFontFamily;
          line-height: 36px;

          @include media-query-large {
            font-size: 1.125rem;
          }

          & > * {
            font-family: inherit !important;
            font-size: inherit !important;
            line-height: inherit !important;
            margin: 0 !important;
          }
        }
      }
    }
  }
}
