@import 'colors';
.loading-spinner {
  &-blocks {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 50px 0;
    overflow: hidden;

    &.hide {
      display: none;
    }
  }
}

.loader-wrapper {
  max-width: 60px;
  height: 60px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  margin: 2rem auto;
}
.loader-wrapper div {
  box-sizing: content-box;
}

.loader-wrapper div {
  width: 30px;
  height: 30px;
  background: $mist;
  animation: loader 1s linear infinite;
}

@keyframes loader {
  0% {
    background: $wave;
  }
  50% {
    background: $mist;
  }
  100% {
    background: $mist;
  }
}
