@import 'layout';
@import 'fonts';

#footer {
  text-align: center;
  clear: both;

  .footer-content {
    width: 50%;
    margin: 0 auto;

    p {
      padding-top: 60px;
      font-size: $baseFont;
      font-weight: $baseWeight;
      text-align: left;
    }
  }
}

@if $UIRefresh2022 {
  footer {
    margin-bottom: 40px;

    p {
      @include eight-column-layout-margin;
      font-family: $baseFontFamily;
      font-weight: normal;
      margin-top: 30px;
      font-size: 16px !important;

      @include media-query-large {
        font-size: 18px !important;
        text-align: center;
      }
    }
  }
}
